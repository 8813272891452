<template>
  <div class="modal-md rounded-lg bg-white p-6 pb-0 md:p-6 md:pb-0">
    <div class="text-center text-xl font-semibold">Choose their samples</div>
    <transition name="fade" mode="out-in">
      <div
        class="flex h-32 w-full items-center justify-center"
        v-if="coreLoading"
      >
        <loading :is-loading="coreLoading" />
      </div>

      <div v-else class="mt-4">
        <div v-if="!hasOptions" class="pb-6 pt-2">
          Looks like you've tried everything and there are no samples available
          to you at this moment! <br /><br />We're always adding more food to
          our range so check back soon!
        </div>
        <div v-else class="mb-12 space-y-4">
          <div>
            Try something new or retry something they havent had for a while...
          </div>
          <div v-if="!subscription.areSamplesFree">
            <div>Each sample cost 75p</div>
          </div>
          <div
            v-for="group in groupedProducts"
            :key="group.label"
            class="space-y-4"
          >
            <div class="font-semibold">{{ mappedTypes[group.label] }}</div>
            <div class="space-y-1">
              <div
                v-for="item in group.items"
                :key="item.sku"
                class="flex items-center space-x-2"
              >
                <div class="-ml-3 h-12 w-12 flex-none">
                  <image-getter
                    classes="
                object-cover rounded-full w-full h-full
              "
                    :url="getImageUrl(item.image.food, 'ar-1_1,w-100')"
                    default="/images/product/awaitingimage.webp"
                    :key="item.sku"
                  />
                </div>
                <div class="flex-grow truncate text-sm">
                  {{ item.shortname || item.name }}
                  <span v-if="item.isKitten">(Kitten)</span>
                </div>
                <div class="w-10">
                  <div
                    v-if="!isSelected(item.sku)"
                    @click="add(item)"
                    :class="
                      limitReached ? 'pointer-events-none opacity-50' : ''
                    "
                    class="flex w-full flex-none cursor-pointer items-center justify-center rounded bg-yellow-200 py-2 text-center text-sm font-semibold hover:bg-yellow-200"
                  >
                    <font-awesome-icon
                      fixed-width
                      class="text-lg"
                      :icon="['far', 'plus']"
                    />
                  </div>
                  <div
                    class="w-full flex-none cursor-pointer rounded bg-transparent py-2 text-center font-semibold text-red-400 hover:bg-red-100"
                    v-else
                    @click="remove(item)"
                  >
                    <font-awesome-icon
                      fixed-width
                      class="text-xl text-red-400"
                      :icon="['far', 'trash-can-xmark']"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      v-if="!coreLoading"
      class="sticky bottom-0 -ml-6 -mr-6 space-y-4 rounded-b-lg bg-white p-6 pt-0"
      :class="hasOptions ? 'border-t' : ''"
    >
      <div
        v-if="hasOptions"
        class="absolute -ml-6 -mr-6 -mt-12 h-12 w-full border-b bg-gradient-to-t from-white"
      ></div>
      <div class="flex-col items-center space-y-4">
        <div
          class="flex w-full flex-col items-center justify-center space-x-4 space-y-2 rounded-lg"
          :class="!subscription.areSamplesFree ? 'bg-blue-100 p-4' : ''"
          v-if="activeSamples.length > 0"
        >
          <div class="flex flex-grow items-center space-y-4">
            <div class="flex w-full items-center justify-center -space-x-4">
              <div
                class="w-12"
                v-for="active in activeSamples"
                :key="`sample-${active.sku}`"
              >
                <image-getter
                  classes="
                object-cover rounded-full w-full h-full
              "
                  :url="getImageUrl(active.image.food, 'ar-1_1,w-100')"
                  default="/images/product/awaitingimage.webp"
                  :key="`images-${active.sku}`"
                />
              </div>
            </div>
          </div>
          <div v-if="!subscription.areSamplesFree" class="text-center">
            <div class="font-medium">Did you know...</div>
            <div class="text-sm text-gray-800">
              These samples are
              <span class="font-medium text-black">Free</span> when you order
              <span class="font-medium text-black">60 or more</span> wet food
              items in your monthly order.
            </div>
          </div>

          <!-- <div
            class="text-center text-gray-700 flex justify-center items-center"
          >
            Selected {{ activeSamples.length }} / 3
          </div> -->
        </div>
        <div class="flex-grow">
          <form novalidate="true" @submit.prevent="submitSamples">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              :is-full="true"
              secondary
              v-if="hasOptions"
              :label="
                samplePrice && samplePrice !== 0
                  ? `Confirm choices for ${formattedSamplePrice}`
                  : 'Confirm Choices'
              "
            />
          </form>
        </div>
      </div>

      <div class="mt-6 text-center text-gray-600">
        <a @click="closeModal">{{ hasOptions ? 'Cancel' : 'Close' }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';

import { mapGetters, mapActions } from 'vuex';
import ImageGetter from '../../ImageGetter.vue';
import Loading from '../../Loading.vue';
import utils from '@/utils';

export default {
  name: 'AddSamplesModal',
  props: ['data'],
  components: {
    LoadingButton,
    ImageGetter,
    Loading
  },
  data() {
    return {
      isLoading: false,
      coreLoading: true,
      availableSamplesFull: [],
      groupedProducts: [],
      mappedTypes: {
        dry: 'Dry',
        wet_can: 'Fillet',
        wet_smooth: 'Smooth',
        wet_tray: 'Pate',
        wet_chunk: 'Chunks',
        wet_tasty: 'Slices',
        wet_pouch: 'Flakes'
      },
      activeSamples: []
    };
  },
  computed: {
    ...mapGetters({
      subscription: 'getCurrentSubscription',
      availableSamples: 'availableSamplesGetter',
      products: 'getProducts',
      currentSamples: 'currentSamplesGetter'
    }),
    limitReached() {
      return this.activeSamples.length >= 3;
    },
    hasOptions() {
      return this.groupedProducts.length > 0;
    },
    hasKitten() {
      console.log('subscription', this.subscription.items);
      // check the subscription to see if there are any kittens that are active
      return this.subscription?.items.some(
        (cat) => cat.type === 'kitten' && cat.active
      );
    },
    samplePrice() {
      if (this.subscription?.areSamplesFree) {
        return false;
      } else {
        return this.activeSamples.length * 0.75;
      }
    },
    formattedSamplePrice() {
      const amount = this.samplePrice;
      if (amount === 0.75) {
        return '75p';
      } else {
        return `£${amount.toFixed(2)}`;
      }
    }
  },

  methods: {
    ...mapActions({
      getSamples: 'getSamples',
      addSample: 'addSample'
    }),
    closeModal: function () {
      this.$parent.$emit('make-close');
    },
    add(item) {
      this.activeSamples.push(item);
    },
    remove(item) {
      this.activeSamples = this.activeSamples.filter(
        (sample) => sample.sku !== item.sku
      );
    },

    isSelected(sku) {
      return this.activeSamples.find((sample) => sample.sku === sku);
    },
    submitSamples() {
      const self = this;
      this.isLoading = true;
      // create an array of the activeSample skus
      const activeSamples = this.activeSamples.map((sample) => sample.sku);
      this.addSample(activeSamples).then((response) => {
        this.$store.commit('update_samples', response);
        this.isLoading = false;
        this.$store.dispatch('getSubscription');
        this.closeModal();

        this.$store.dispatch('sendMpEvent', {
          event: 'Updated Samples',
          properties: {
            location: self.$route.name,
            type: 'EVT',
            samplesCount: self.activeSamples?.length,
            samples: self.activeSamples
          }
        });
      });
    },

    groupSamples() {
      let products = this.availableSamples.map((sample) => {
        const product = this.products.find(
          (product) => product.sku === sample.sku
        );
        return {
          ...product
        };
      });
      console.log('products 1', products);

      // filter out kitten products if cat is adult
      if (!this.hasKitten) {
        console.log('filter products that are not kittens');
        products = products.filter((product) => !product.isKitten);
      }

      // for each of the skus in the currentSamples array find the product and push to the products array
      this.currentSamples.forEach((item) => {
        const product = this.products.find((p) => p.sku === item);
        // if the product already existss dont push it
        if (!products.find((p) => p.sku === product.sku)) {
          products.push(product);
        }
      });

      console.log('products', products);

      let groupedProducts = products.reduce((r, a) => {
        r[a.foodtype] = [...(r[a.foodtype] || []), a];

        return r;
      }, {});

      this.groupedProducts = Object.keys(groupedProducts).map((key) => ({
        label: key,
        items: groupedProducts[key].sort((a, b) => a.name.localeCompare(b.name))
      }));

      // for each of the grouped items arrays make the first item have an isSelected property of true and the rest false
      this.groupedProducts.forEach((group) => {
        group.items = group.items;
      });

      // remove groups that have no items
      this.groupedProducts = this.groupedProducts.filter(
        (group) => group.items.length > 0
      );
      const order = [
        'wet_can',
        'wet_pouch',
        'wet_chunk',
        'wet_smooth',
        'wet_tray',
        'dry'
      ];
      // order the grouped products based on the order array
      this.groupedProducts.sort((a, b) => {
        return order.indexOf(a.label) - order.indexOf(b.label);
      });
      setTimeout(() => {
        this.coreLoading = false;
      }, 1000);
    },
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  },

  mounted() {
    this.getSamples().then(() => {
      this.groupSamples();
      // create a copy of the currentSamples array
    });

    this.activeSamples = [...this.data];
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
